import { call, put, takeLatest } from 'redux-saga/effects'
import { TeacherResponse } from 'src/service/api/api.types'
import { apiPrivate } from 'src/service/api/api.private'
import * as Sentry from '@sentry/browser'
import { getTeacherRequest, getTeacherFailure, getTeacherSuccess } from '.'

export function* updateTeacherRequest(action) {
  try {
    const res: TeacherResponse = yield call(apiPrivate.getTeacher.bind(apiPrivate), action.payload)

    if (res.kind === 'ok') {
      yield put(getTeacherSuccess({ teacher: res.data }))
    }

    if (res.kind === 'not-found') {
      yield put(getTeacherFailure({ errors: res.kind }))
    }
  } catch (errors) {
    // Sentry
    Sentry.captureException(errors)
  }
}

export default function* teacherSaga() {
  yield takeLatest(getTeacherRequest.type, updateTeacherRequest)
}

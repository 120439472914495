import { call, put, takeLatest } from 'redux-saga/effects'
import { CategoriesResponse } from 'src/service/api/api.types'
import { apiPublic } from 'src/service/api/api.public'
import * as Sentry from "@sentry/browser";
import {setCategoriesRequest, getCategoriesSuccess, getCategoriesFailure} from '.'

export function* updateCategoriesRequest() {
  try {
    const res: CategoriesResponse = yield call(apiPublic.getCategories.bind(apiPublic))

    if (res.kind === 'ok') {
      yield put(getCategoriesSuccess({ categories: res.data }))
    }

    if(res.kind === 'not-found') {
      yield put(getCategoriesFailure({ errors: res.kind }))
    }
  } catch (errors) {
    // Sentry
    Sentry.captureException(errors)
  }
}

export default function* categoriesSaga() {
  yield takeLatest(setCategoriesRequest.type, updateCategoriesRequest)
}

export const spacing = {
  micro: '2px',
  tiny: '4px',
  extraSmall: '8px',
  small: '12px',
  medium: '16px',
  large: '24px',
  extraLarge: '32px',
  huge: '48px',
  massive: '64px',
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TxKeyPath } from 'src/i18n'
import { GeneralApiProblem } from 'src/service/api/apiProblem'
import i18n from '../../i18n'

interface SetErrorPayload {
  response: GeneralApiProblem
  customMessages?: {
    [key in GeneralApiProblem['kind']]?: TxKeyPath
  }
}

export interface ErrorState {
  error?: string
}

const initialState: ErrorState = {
  error: undefined,
}

const errorsSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<SetErrorPayload>) => {
      const {
        payload: { response, customMessages },
      } = action

      if (response.kind === 'bad-data') {
        state.error = response.errors.shift()
      } else if (customMessages) {
        for (const kind in customMessages) {
          if (response.kind === kind) {
            const message = customMessages[kind]
            state.error = i18n.t(message || 'toast.unknownError')
            break
          }
        }
      } else {
        state.error = i18n.t('toast.unknownError')
      }
    },

    reset: state => {
      state.error = undefined
    },
  },
})

export const { setError, reset } = errorsSlice.actions

export const errorsReducer = errorsSlice.reducer

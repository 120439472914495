import {
  ApiGetBanner,
  CategoriesApi,
  CategoriesWithArea,
  CategoryApi,
  CategoryModules,
  ContactRequest,
  FaqApi,
  IExtendedPlan,
  INews,
  ISlider,
  ITeachers,
  ITestimonials,
  ITutorials,
  NewsRecentResponse,
  NoContentResponse,
  PayloadTestimonials,
  PlansResponse,
  TutorialResponse,
} from 'src/service/api/api.types'
import { ApisauceInstance, create } from 'apisauce'
import { getGeneralApiProblem } from 'src/service/api/apiProblem'
import * as Sentry from '@sentry/browser'

export class ApiPublic {
  apisauce: ApisauceInstance

  constructor() {
    this.apisauce = create({
      baseURL: `${process.env.NEXT_PUBLIC_API}`,
      timeout: 10000,
      headers: {
        Accept: 'application/json',
      },
    })
  }

  public async getCategories() {
    try {
      const res = await this.apisauce.get<CategoriesApi>('v1/lms/categories?perPage=100')
      return { kind: 'ok', data: res.data as CategoriesApi }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async getCategoriesByArea(categoryAreaSlug: string) {
    try {
      const res = await this.apisauce.get<CategoriesWithArea>(
        `v1/lms/categories/by-area/${categoryAreaSlug}`,
      )
      return { kind: 'ok', data: res.data as CategoriesWithArea }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async getCategory(uuid: string) {
    try {
      const res = await this.apisauce.get<CategoryApi>(`v1/lms/categories/${uuid}`)
      return { kind: 'ok', data: res.data as CategoryApi }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async getSliders() {
    try {
      const res = await this.apisauce.get<ISlider[]>('v1/general/slides')
      return { kind: 'ok', data: res.data as ISlider[] }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async getFaq() {
    try {
      const res = await this.apisauce.get<FaqApi>('v1/general/faq')
      return { kind: 'ok', data: res.data }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async getBanners() {
    try {
      const res = await this.apisauce.get<ApiGetBanner>('v1/general/banners')
      return { kind: 'ok', data: res.data as ApiGetBanner }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async getModules(uuid: string) {
    try {
      const res = await this.apisauce.get<CategoryModules>(`v1/lms/category-modules/${uuid}`)
      return { kind: 'ok', data: res.data as CategoryModules }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async getTestimonials() {
    try {
      const res = await this.apisauce.get<ITestimonials[]>('v1/general/testimonials')
      return { kind: 'ok', data: res.data as ITestimonials[] }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async setTestimonials(req: PayloadTestimonials): Promise<NoContentResponse> {
    try {
      const res = await this.apisauce.post<NoContentResponse>(
        'v1/general/testimonials',
        req.payload,
        {
          headers: {
            recaptcha: req.recaptcha,
          },
        },
      )

      if (!res.ok) {
        const problem = getGeneralApiProblem(res)
        if (problem) return problem
      }

      return { kind: 'ok' }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async getNewsRecent(total?: number): Promise<NewsRecentResponse> {
    try {
      const res = await this.apisauce.get<INews[]>('v1/general/news', { perPage: total })
      return { kind: 'ok', data: res.data as INews[] }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async getNews(uuid: string) {
    try {
      const res = await this.apisauce.get<INews>(`v1/general/news/${uuid}`)
      return { kind: 'ok', data: res.data as INews }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async getTeachers() {
    try {
      const res = await this.apisauce.get<ITeachers>('v1/lms/teachers')
      return { kind: 'ok', data: res.data as ITeachers }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async sendContact(req: ContactRequest): Promise<NoContentResponse> {
    try {
      const response = await this.apisauce.post('v1/general/contact', req.payload, {
        headers: {
          recaptcha: req.recaptcha,
          'Content-Type': 'multipart/form-data',
        },
      })
      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }
      return { kind: 'ok' }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async getTutorials(): Promise<TutorialResponse> {
    try {
      const res = await this.apisauce.get<ITutorials[]>('v1/general/tutorials')
      return { kind: 'ok', data: res.data as ITutorials[] }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async getPlans(): Promise<PlansResponse> {
    try {
      const res = await this.apisauce.get<IExtendedPlan[]>('v1/subscriptions/plans')
      return { kind: 'ok', data: res.data as IExtendedPlan[] }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }
}

export const apiPublic = new ApiPublic()

import { combineReducers } from '@reduxjs/toolkit'
import { userReducer } from 'src/store/user'
import { generalReducer, GeneralState } from 'src/store/general'
import { UserState } from 'src/store/user/types'
import {categoryReducer} from "src/store/category";
import {courseReducer} from "src/store/lessons";
import {CourseState} from "src/store/lessons/types";
import {searchReducer} from "src/store/search";
import {SearchState} from "src/store/search/types";
import {teacherReducer} from "src/store/teachers";
import {TeacherState} from "src/store/teachers/types";
import {newsReducer} from "src/store/news";
import {NewsState} from "src/store/news/types";
import {CategoryState} from "src/store/category/types";
import {testimonialReducer} from "src/store/testimonials";
import {TestimonialState} from "src/store/testimonials/types";
import { errorsReducer, ErrorState } from './errors'

const rootReducer = combineReducers({
  user: userReducer,
  teachers: teacherReducer,
  testimonial: testimonialReducer,
  news: newsReducer,
  course: courseReducer,
  search: searchReducer,
  general: generalReducer,
  category: categoryReducer,
  errors: errorsReducer,
})

export interface State {
  user: UserState
  teachers: TeacherState
  testimonial: TestimonialState
  news: NewsState
  course: CourseState
  search: SearchState
  general: GeneralState
  category: CategoryState
  errors: ErrorState
}

export default rootReducer

import { call, put, takeLatest } from 'redux-saga/effects'
import {NewsResponse} from 'src/service/api/api.types'
import { apiPublic } from 'src/service/api/api.public'
import * as Sentry from "@sentry/browser";
import { getNewsRequest, getNewsSuccess } from '.'

export function* updateNewsRequest(action) {
  try {
    const res: NewsResponse = yield call(apiPublic.getNews.bind(apiPublic), action.payload)

    if (res.kind === 'ok') {
      yield put(getNewsSuccess({ news: res.data }))
    }
  } catch (errors) {
    // Sentry
    Sentry.captureException(errors)
  }
}

export default function* newsSaga() {
  yield takeLatest(getNewsRequest.type, updateNewsRequest)
}

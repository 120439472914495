export const typography = {
  primary: {
    normal: {
      'font-family': 'Montserrat',
      'font-weight': '400',
    },
    medium: {
      'font-family': 'Montserrat',
      'font-weight': '500',
    },
    semiBold: {
      'font-family': 'Montserrat',
      'font-weight': '600',
    },
    bold: {
      'font-family': 'Montserrat',
      'font-weight': '700',
    },
  },
  heading: {
    medium: {
      'font-family': 'Montserrat',
      'font-weight': '500',
      'font-style': 'normal',
    },
  },
}

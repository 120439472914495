import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {GetTeacherSuccessPayload, GetTeacherFailurePayload, TeacherState} from './types'

const initialState: TeacherState = {
  teacher: null,
  isLoading: false,
  errors: null,
}

const teacherSlice = createSlice({
  name: 'teacher',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getTeacherRequest: (state, action: PayloadAction<string>) => {
      state.isLoading = true
      state.errors = []
    },
    getTeacherSuccess: (state, action: PayloadAction<GetTeacherSuccessPayload>) => {
      state.isLoading = false
      state.teacher = action.payload.teacher
      state.errors = []
    },
    getTeacherFailure: (state, action: PayloadAction<GetTeacherFailurePayload>) => {
      state.isLoading = false
      state.errors = action.payload.errors
    },
  },
})

export const {
  getTeacherRequest,
  getTeacherSuccess,
  getTeacherFailure,
} = teacherSlice.actions

export const teacherReducer = teacherSlice.reducer

import { call, put, takeLatest } from 'redux-saga/effects'
import {LastLessonResponse, LessonResponse} from 'src/service/api/api.types'
import { apiPrivate } from 'src/service/api/api.private'
import * as Sentry from "@sentry/browser";
import {
  getLessonSuccess,
  getLessonFailure,
  getLessonRequest,
  setModuleActive,
  setTabIndex,
  getLastLessonSuccess, getLastLessonRequest,
} from '.'

export function* updateLastLessons() {
  try {
    const res: LastLessonResponse = yield call(apiPrivate.getLastLessons.bind(apiPrivate))

    if (res.kind === 'ok') {
      yield put(getLastLessonSuccess({ lastLesson: res.data }))
    }
  } catch (errors) {
    // Sentry
    Sentry.captureException(errors)
  }
}


export function* updateLessonRequest(action) {
  try {
    const res: LessonResponse = yield call(
      apiPrivate.getLessonExtended.bind(apiPrivate),
      action.payload,
    )

    if (res.kind === 'ok') {
      let moduleId = ''
      yield put(getLessonSuccess({ module: res.data }))
      res.data.category.modules.forEach(item => {
        if (item.lessons.some(lesson => lesson.id === res.data.id)) {
          moduleId = item.id
        }
      })
      yield put(setModuleActive(moduleId))
      yield put(setTabIndex(0))
    }
    if(res.kind === 'unauthorized' || res.kind === 'not-found') {
      yield put(getLessonFailure({ errors: res.kind }))
    }


  } catch (errors) {
    // Sentry
    Sentry.captureException(errors)
  }
}

export default function* lessonSaga() {
  yield takeLatest(getLessonRequest.type, updateLessonRequest)
  yield takeLatest(getLastLessonRequest.type, updateLastLessons)
}

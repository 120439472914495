import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ITestimonials } from 'src/service/api/api.types'
import {
  GetTestimonialSuccessPayload,
  GetTestimonialFailurePayload,
  TestimonialState,
} from './types'

const initialState: TestimonialState = {
  testimonials: [],
  isLoading: false,
  errors: null,
}

const testimonialSlice = createSlice({
  name: 'testimonial',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getTestimonialRequest: state => {
      state.isLoading = true
      state.errors = []
    },
    getTestimonialSuccess: (state, action: PayloadAction<GetTestimonialSuccessPayload>) => {
      state.isLoading = false
      state.testimonials = action.payload.testimonials
      state.errors = []
    },
    getTestimonialFailure: (state, action: PayloadAction<GetTestimonialFailurePayload>) => {
      state.isLoading = false
      state.errors = action.payload.errors
    },
    updateTestimonial: (state, action: PayloadAction<ITestimonials>) => {
      state.testimonials.push(action.payload)
    },
  },
})

export const {
  getTestimonialRequest,
  getTestimonialSuccess,
  getTestimonialFailure,
  updateTestimonial,
} = testimonialSlice.actions

export const testimonialReducer = testimonialSlice.reducer

import { call, put, takeLatest } from 'redux-saga/effects'
import {SearchResponse} from 'src/service/api/api.types'
import {apiPrivate} from "src/service/api/api.private";
import * as Sentry from "@sentry/browser";
import { getSearchSuccess, getSearchRequest } from '.'

export function* updateSearchRequest(action) {
  try {
    const res: SearchResponse = yield call(
      apiPrivate.search.bind(apiPrivate),
      action.payload,
    )

    if (res.kind === 'ok') {
      yield put(getSearchSuccess({ search: res.data }))
    }
  } catch (errors) {
    // Sentry
    Sentry.captureException(errors)
  }
}

export default function* searchSaga() {
  yield takeLatest(getSearchRequest.type, updateSearchRequest)
}

import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface GeneralState {
  classMenu: boolean
  search: string
}

const initialState: GeneralState = {
  classMenu: false,
  search: ''
}

const menuPlatformSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setClass: state => {
      state.classMenu = !state.classMenu
    },
    setSearch: (state, search: PayloadAction<string>) => {
      state.search = search.payload
    }
  },
})

export const { setClass, setSearch } = menuPlatformSlice.actions

export const generalReducer = menuPlatformSlice.reducer

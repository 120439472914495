/* eslint-disable @typescript-eslint/ban-types */
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import ptBr from './locales/pt_Br.json'

i18n.use(initReactI18next).init({
  lng: 'pt_BR',
  fallbackLng: 'pt_BR',
  resources: {
    pt_BR: {
      translation: ptBr,
    },
  },
  debug: false,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n

type Translations = typeof ptBr

export type TxKeyPath = RecursiveKeyOf<Translations>

type RecursiveKeyOf<TObj extends object> = {
  [TKey in keyof TObj & (string | number)]: RecursiveKeyOfHandleValue<TObj[TKey], `${TKey}`>
}[keyof TObj & (string | number)]

type RecursiveKeyOfInner<TObj extends object> = {
  [TKey in keyof TObj & (string | number)]: RecursiveKeyOfHandleValue<
    TObj[TKey],
    `['${TKey}']` | `.${TKey}`
  >
}[keyof TObj & (string | number)]

type RecursiveKeyOfHandleValue<TValue, Text extends string> = TValue extends unknown[]
  ? Text
  : TValue extends object
  ? Text | `${Text}${RecursiveKeyOfInner<TValue>}`
  : Text

import {
  AuthTokens,
  ChangePassword,
  IComment,
  EditAuth,
  ICategory,
  IProfile,
  Lesson,
  LoginRequest,
  NoContentResponse,
  ResetPasswordRequest,
  SendCategoryResponse,
  SendCommentResponse,
  SendRecoveryCodeRequest,
  UserResponse,
  UserTokensResponse,
  VerifyCodeRequest,
  PayloadComment,
  ILessonExtended,
  LessonResponse,
  CategoryExtendedApi,
  ITeacher,
  SearchApi,
  CategoryApi,
  CategoryModules,
} from 'src/service/api/api.types'
import { ApiResponse, ApisauceInstance, create } from 'apisauce'
import { getUserTokens } from 'src/utils/getUserTokens'
import * as Sentry from '@sentry/browser'
import { getGeneralApiProblem } from './apiProblem'

export class ApiPrivate {
  apisauce: ApisauceInstance

  constructor() {
    this.apisauce = create({
      baseURL: `${process.env.NEXT_PUBLIC_API}`,
      timeout: 10000,
      headers: {
        Accept: 'application/json',
      },
    })

    this.apisauce.axiosInstance.interceptors.request.use(async config => {
      const authTokens = getUserTokens()

      if (authTokens?.accessToken) {
        config.headers.Authorization = `Bearer ${authTokens.accessToken}`
      }

      return config
    })

    const interceptor = this.apisauce.axiosInstance.interceptors.response.use(
      response => response,
      async error => {
        const config = error?.config
        const authTokens = getUserTokens()
        const blacklist = ['v1/auth/change-password']

        if (blacklist.some(urlPattern => config?.url.includes(urlPattern))) {
          return Promise.reject(error)
        }

        if ([401].includes(error.response.status) && authTokens) {
          this.apisauce.axiosInstance.interceptors.response.eject(interceptor)
          const response = await this.refreshToken(error)

          if (response?.data?.accessToken) {
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            return this.apisauce.axiosInstance(config)
          }
        }

        return Promise.reject(error)
      },
    )
  }

  refreshToken(error): Promise<ApiResponse<AuthTokens>> {
    return new Promise((resolve, reject) => {
      const authTokens = getUserTokens()

      if (!authTokens) {
        return reject(error)
      }

      const body = {
        token: authTokens.refreshToken,
      }

      this.apisauce
        .post<AuthTokens>('v1/auth/refresh', body, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.accessToken}`,
          },
        })
        .then(res => {
          if (res.ok) {
            localStorage.setItem('authTokens', JSON.stringify(res.data))
            return resolve(res)
          }
          localStorage.removeItem('authTokens')
          window.location.replace('/login')
          return reject(error)
        })
        .catch(() => {
          localStorage.removeItem('authTokens')
          window.location.replace('/login')
          return reject(error)
        })
    })
  }

  async login(req: LoginRequest): Promise<UserTokensResponse> {
    try {
      const response = await this.apisauce.post('v1/auth/login', req.payload, {
        headers: {
          recaptcha: req.recaptcha,
        },
      })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok', data: response.data as AuthTokens }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  async logout(): Promise<NoContentResponse> {
    try {
      const response = await this.apisauce.post('v1/auth/logout')

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok' }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  async getUserData(): Promise<UserResponse> {
    try {
      const response = await this.apisauce.get('v1/auth/me')

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok', data: response.data as IProfile }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  async sendRecoveryCode(req: SendRecoveryCodeRequest): Promise<NoContentResponse> {
    try {
      const response = await this.apisauce.post('v1/auth/reset-password/recovery', req.payload, {
        headers: {
          recaptcha: req.recaptcha,
        },
      })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok' }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  async verifyCode(data: VerifyCodeRequest): Promise<NoContentResponse> {
    try {
      const response = await this.apisauce.post('v1/auth/reset-password/verify', data)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok' }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  async resetPassword(data: ResetPasswordRequest): Promise<NoContentResponse> {
    try {
      const response = await this.apisauce.post('v1/auth/reset-password/reset', data)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok' }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async setCategory(categories: string[]): Promise<SendCategoryResponse> {
    try {
      const response = await this.apisauce.post<ICategory>(`v1/lms/categories/attach`, {
        categories,
      })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok', data: response.data as ICategory }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async updateProfile(body: EditAuth): Promise<UserResponse> {
    try {
      const response = await this.apisauce.patch<IProfile>('v1/auth/me', body)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok', data: response.data as IProfile }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async uploadProfile(file: FormData): Promise<UserResponse> {
    try {
      const response = await this.apisauce.post<IProfile>('v1/auth/profile-photo', file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok', data: response.data as IProfile }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async changePassword(body: ChangePassword): Promise<UserResponse> {
    try {
      const response = await this.apisauce.patch<IProfile>('v1/auth/change-password', body)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok', data: response.data as IProfile }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async search(search: string) {
    try {
      const res = await this.apisauce.get<SearchApi>('v1/lms/search', {
        search,
      })
      return { kind: 'ok', data: res.data as SearchApi }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async getCategory(slug: string) {
    try {
      const res = await this.apisauce.get<CategoryApi>(`v1/lms/categories/${slug}`)
      return { kind: 'ok', data: res.data as CategoryApi }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async getCategoryExtended(slug: string) {
    try {
      const res = await this.apisauce.get<CategoryExtendedApi>(`v1/lms/categories/${slug}/extended`)
      return { kind: 'ok', data: res.data as CategoryExtendedApi }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async getCategoryModule(slug: string, order?: string) {
    try {
      const res = await this.apisauce.get<CategoryModules>(`v1/lms/category-modules/${slug}`, {
        order,
      })
      return { kind: 'ok', data: res.data as CategoryModules }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async getTeacher(slug: string) {
    try {
      const res = await this.apisauce.get<ITeacher>(`v1/lms/teachers/${slug}`)
      return { kind: 'ok', data: res.data as ITeacher }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async getLessonExtended(slug: string): Promise<LessonResponse> {
    try {
      const response = await this.apisauce.get<ILessonExtended>(`v1/lms/lessons/${slug}/extended`)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok', data: response.data as ILessonExtended }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async setToggleWatch(uuid: string) {
    try {
      const response = await this.apisauce.patch<void>(`v1/lms/lessons/${uuid}/toggle-watch`)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok' }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async setToggleFavorite(uuid: string) {
    try {
      const response = await this.apisauce.patch<void>(`v1/lms/lessons/${uuid}/toggle-favorite`)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok' }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async sendComment(uuid: string, body: PayloadComment): Promise<SendCommentResponse> {
    try {
      const response = await this.apisauce.post<IComment>(`v1/lms/lessons/${uuid}/comments`, body)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      return { kind: 'ok', data: response.data as IComment }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async getLastLessons() {
    try {
      const response = await this.apisauce.get<Lesson[]>('v1/lms/lessons/last')

      return { kind: 'ok', data: response.data as Lesson[] }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async getLastLessonsWatched() {
    try {
      const response = await this.apisauce.get<Lesson[]>('v1/lms/lessons/watched/last')

      return { kind: 'ok', data: response.data as Lesson[] }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }

  public async getFavoriteLessons() {
    try {
      const response = await this.apisauce.get<Lesson[]>('v1/lms/lessons/favorite')

      return { kind: 'ok', data: response.data as Lesson[] }
    } catch (err) {
      // Sentry
      Sentry.captureException(err)
      return { kind: 'server' }
    }
  }
}

export const apiPrivate = new ApiPrivate()

import React from 'react'
import { Balls, Screen } from './styles'

export const LoadingScreen = () => (
  <Screen>
    <Balls>
      <div className="ball one" />
      <div className="ball two" />
      <div className="ball three" />
    </Balls>
  </Screen>
)

/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/no-danger */
import React, { FC, useEffect, useState } from 'react'
import { AppProps } from 'next/app'
import store from 'src/store'
import { Provider } from 'react-redux'
import GlobalBaseStyle from 'src/styles/GlobalBaseStyle'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-circular-progressbar/dist/styles.css'
import 'react-tabs/style/react-tabs.css'
import 'src/i18n'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ThemeProvider } from 'styled-components'
import theme from 'src/theme/theme'
import Head from 'next/head'
import { LoadingScreen } from 'src/components/Common/LoadingScreen'

const App: FC<AppProps> = ({ Component, pageProps }) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [])

  return (
    <Provider store={store}>
      <GoogleReCaptchaProvider
        reCaptchaKey={`${process.env.NEXT_PUBLIC_RECAPTCHA_ID}`}
        language="pt-br"
      >
        <ThemeProvider theme={theme}>
          <GlobalBaseStyle />
          <Head>
            <meta charSet="uft-8" />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
              href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap"
              rel="stylesheet"
            />
            <link rel="shortcut icon" href="/favicon.png" />
            <meta
              name="keywords"
              content="cordas e musica, escola de música, cursos de violão, guitarra, música"
            />
            <meta name="author" content="Cordas e Música" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <meta property="og:type" content="website" key="og:type" />
            <meta
              property="og:title"
              content="Cordas e Música - Sua escola de música na internet"
              key="og:title"
            />
            <meta
              property="og:description"
              content="Sua escola de música na internet"
              key="og:description"
            />
            <meta
              property="twitter:title"
              content="Cordas e Música - Sua escola de música na internet"
            />
            <meta property="twitter:description" content="Sua escola de música na internet" />
            <title>Cordas e Música - Sua escola de música na internet</title>
            <meta name="description" content="Sua escola de música na internet" />
            {/* Google Tag Manager */}
            <script
              dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WQGWLSVV');
`,
              }}
            />
            {/* End Google Tag Manager */}
          </Head>
          {/* Google Tag Manager (noscript) */}
          <noscript>
            <iframe
              src="https://www.googletagmanager.com/ns.html?id=GTM-WQGWLSVV"
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
            />
          </noscript>

          {/* End Google Tag Manager (noscript) */}
          {loaded ? <Component {...pageProps} /> : <LoadingScreen />}
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </ThemeProvider>
      </GoogleReCaptchaProvider>
    </Provider>
  )
}

export default App

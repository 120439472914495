import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CategoriesApi } from 'src/service/api/api.types'
import {
  CategoryState,
  GetCategoryFailurePayload,
  GetCategorySuccessPayload,
} from 'src/store/category/types'

const initialState: CategoryState = {
  idCategory: '',
  categories: null,
  isLoading: false,
  errors: null,
}

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setIdCategory: (state, action: PayloadAction<string>) => {
      state.idCategory = action.payload
    },
    setCategoriesRequest(state) {
      state.isLoading = true
      state.errors = []
    },
    getCategoriesSuccess: (state, action: PayloadAction<GetCategorySuccessPayload>) => {
      state.isLoading = false
      state.categories = action.payload.categories
      state.errors = []
    },
    getCategoriesFailure: (state, action: PayloadAction<GetCategoryFailurePayload>) => {
      state.isLoading = false
      state.errors = action.payload.errors
    },
  },
})

export const { setIdCategory, setCategoriesRequest, getCategoriesSuccess, getCategoriesFailure } =
  categorySlice.actions

export const categoryReducer = categorySlice.reducer

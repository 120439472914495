import { call, put, takeLatest } from 'redux-saga/effects'
import {TestimonialResponse} from 'src/service/api/api.types'
import {apiPublic} from "src/service/api/api.public";
import * as Sentry from "@sentry/browser";
import { getTestimonialSuccess, getTestimonialRequest } from '.'

export function* updateTestimonialsRequest() {
  try {
    const res: TestimonialResponse = yield call(
      apiPublic.getTestimonials.bind(apiPublic),
    )

    if (res.kind === 'ok') {
      yield put(getTestimonialSuccess({ testimonials: res.data }))
    }
  } catch (errors) {
    // Sentry
    Sentry.captureException(errors)
  }
}

export default function* testimonialSaga() {
  yield takeLatest(getTestimonialRequest.type, updateTestimonialsRequest)
}

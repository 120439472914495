import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IComment, ICommentApi } from 'src/service/api/api.types'
import {
  CourseState,
  GetCourseSuccessPayload,
  GetCourseFailurePayload,
  GetLessonSuccessPayload,
} from './types'

const initialState: CourseState = {
  module: null,
  isLoading: false,
  errors: null,
  moduleActive: '',
  tabIndex: 0,
  lastLessons: null,
}

const courseSlice = createSlice({
  name: 'lesson',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getLessonRequest: (state, action: PayloadAction<string>) => {
      state.isLoading = true
      state.errors = []
    },
    getLessonSuccess: (state, action: PayloadAction<GetCourseSuccessPayload>) => {
      state.isLoading = false
      state.module = action.payload.module
      state.errors = []
    },
    getLessonFailure: (state, action: PayloadAction<GetCourseFailurePayload>) => {
      state.isLoading = false
      state.errors = action.payload.errors
    },

    updateComments: (state, action: PayloadAction<IComment>) => {
      if (action.payload.parentLessonCommentId) {
        state.module?.comments.forEach(comment => {
          if (comment.id === action.payload.parentLessonCommentId) {
            if(!comment.children) {
              comment.children = []
            }
            comment.children.push(action.payload)
          }
        })
      } else {
        state.module?.comments.unshift(action.payload as ICommentApi)
      }
    },
    setModuleActive: (state, action: PayloadAction<string>) => {
      state.moduleActive = action.payload
    },
    setTabIndex: (state, action: PayloadAction<number>) => {
      state.tabIndex = action.payload
    },
    getLastLessonRequest: state => {
      state.isLoading = true
      state.errors = []
    },
    getLastLessonSuccess: (state, action: PayloadAction<GetLessonSuccessPayload>) => {
      state.isLoading = false
      state.lastLessons = action.payload.lastLesson
      state.errors = []
    },
  },
})

export const {
  getLessonRequest,
  getLessonSuccess,
  getLessonFailure,
  updateComments,
  setModuleActive,
  setTabIndex,
  getLastLessonRequest,
  getLastLessonSuccess,
} = courseSlice.actions

export const courseReducer = courseSlice.reducer

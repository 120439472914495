const palette = {
  neutral100: '#FFFFFF',
  neutral200: '#F7F8FA',
  neutral300: '#EEEEEE',
  neutral400: '#E1E1E1',
  neutral450: '#CCCCCC',
  neutral500: '#BFC9CF',
  neutral600: '#AAB7BF',
  neutral700: '#8191A0',
  neutral800: '#6C7D90',
  neutral900: '#273444',
  neutral1000: '#131C26',

  black: '#000000',

  primary100: '#ABC4D4',
  primary200: '#7FB3B9',
  primary300: '#5F9DA8',
  primary400: '#3F8597',
  primary500: '#206D85',
  primary600: '#165275',

  secondary100: '#9EE2E6',
  secondary200: '#7FD4DD',
  secondary300: '#5FC5D4',
  secondary400: '#3FB3CC',
  secondary500: '#209FC3',
  secondary600: '#0089BA',

  angry100: '#FFEDED',
  angry500: '#E54242',

  red500: '#C8242B',

  success100: '#D9EAE0',
  success500: '#10B258',

  green400: '#8AC811',

  warning100: '#FFFAEA',
  warning500: '#E5B428',

  info100: '#E9F8FF',
  info500: '#1CA4E5',

  overlay20: 'rgba(0, 0, 0, 0.2)',
  overlay50: 'rgba(0, 0, 0, 0.5)',
  overlay70: 'rgba(0, 0, 0, 0.7)',
  overlay90: 'rgba(0, 0, 0, 0.9)',

  stars: '#FFC82C',

  balance: '#13CE66',

  negativeBalance: '#FF4949',

  white: '#FFFFFF',
}

export const colors = {
  palette,

  border: palette.neutral400,
  secondaryBorderColor: palette.neutral200,

  transparent: 'rgba(255, 255, 255, 0)',

  success: palette.success500,
  error: palette.angry500,

  primaryBackgroundColor: palette.primary600,
  secondaryBackgroundColor: palette.secondary600,
  supportBackgroundColor: palette.primary100,

  primaryTextColor: palette.neutral900,
  secondaryTextColor: palette.neutral800,

  white: palette.white,

  background: palette.white,
}

import { AuthTokens } from 'src/service/api/api.types'

export const getUserTokens = (): AuthTokens | null => {
  try {
    const rawAuthToken = localStorage.getItem('authTokens')
    return JSON.parse(rawAuthToken as string)
  } catch (err) {
    return null
  }
}

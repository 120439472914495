import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {GetNewsSuccessPayload, GetNewsFailurePayload, NewsState} from './types'

const initialState: NewsState = {
  news: null,
  isLoading: false,
  errors: null,
}

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getNewsRequest: (state, action: PayloadAction<string>) => {
      state.isLoading = true
      state.errors = []
    },
    getNewsSuccess: (state, action: PayloadAction<GetNewsSuccessPayload>) => {
      state.isLoading = false
      state.news = action.payload.news
      state.errors = []
    },
    getNewsFailure: (state, action: PayloadAction<GetNewsFailurePayload>) => {
      state.isLoading = false
      state.errors = action.payload.errors
    },
  },
})

export const {
  getNewsRequest,
  getNewsSuccess,
  getNewsFailure,
} = newsSlice.actions

export const newsReducer = newsSlice.reducer

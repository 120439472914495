import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

const GlobalBaseStyle = createGlobalStyle`
  ${normalize};

  * {
    padding: 0;
    margin: 0;
    outline: none;
    box-sizing: border-box;
    ${({ theme }) => ({ ...theme.typography.primary.normal })};
  }

  html {
    scroll-behavior: smooth !important;
  }

  body {
    background-color: ${({ theme }) => theme.colors.background};
    overflow-x: hidden;
  }

  button {
    border: none;
    cursor: pointer;
  }

  a {
    color: ${({ theme }) => theme.colors.secondaryTextColor};
    text-decoration: none;
  }

  p {
    color: ${({ theme }) => theme.colors.secondaryTextColor};
    ${({ theme }) => ({ ...theme.size.xs })};
    margin-bottom: ${p => p.theme.spacing.tiny};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) => theme.colors.primaryTextColor};
    ${({ theme }) => ({ ...theme.typography.heading.medium })};
  }

  .stop-scrolling {
    overflow: hidden;
  }

  @keyframes fade-and-slide-in {
    0% {
      right: -100%;
    }
    100% {
      right: 0;
    }
  }

  * {
    scrollbar-width: auto;
    scrollbar-color: ${p => p.theme.colors.secondaryBackgroundColor} ${p =>
  p.theme.colors.transparent};
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px;
  }

  *::-webkit-scrollbar-track {
    background: ${p => p.theme.colors.transparent};
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${p => p.theme.colors.secondaryBackgroundColor};
    border-radius: 10px;
    border: 3px solid ${p => p.theme.colors.transparent};
  }

  .slick-dots {
    position: static;
    margin-top: 16px !important;
    margin-bottom: 16px;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
`

export default GlobalBaseStyle

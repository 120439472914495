import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GetSearchSuccessPayload, GetSearchFailurePayload, SearchState } from './types'

const initialState: SearchState = {
  lessons: null,
  isLoading: false,
  errors: null,
}

const searchSlice = createSlice({
  name: 'lesson',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getSearchRequest: (state, action: PayloadAction<string>) => {
      state.isLoading = true
      state.errors = []
    },
    getSearchSuccess: (state, action: PayloadAction<GetSearchSuccessPayload>) => {
      state.isLoading = false
      state.lessons = action.payload.search
      state.errors = []
    },
    getSearchFailure: (state, action: PayloadAction<GetSearchFailurePayload>) => {
      state.isLoading = false
      state.errors = action.payload.errors
    },
  },
})

export const { getSearchRequest, getSearchSuccess, getSearchFailure } = searchSlice.actions

export const searchReducer = searchSlice.reducer

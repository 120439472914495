import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  GetProfileSuccessPayload,
  GetProfileFailurePayload,
  UserState,
  GetLessonWatchedSuccessPayload,
  GetFavoriteLessonSuccessPayload,
} from './types'

const initialState: UserState = {
  profile: null,
  isLoading: false,
  errors: null,
  watchedLessons: [],
  favoriteLessons: [],
  isAuthenticated: null,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getProfileRequest: state => {
      state.isLoading = true
      state.isAuthenticated = null
      state.errors = []
    },
    getProfileSuccess: (state, action: PayloadAction<GetProfileSuccessPayload>) => {
      state.isLoading = false
      state.isAuthenticated = true
      state.profile = action.payload.profile
      state.errors = []
    },
    getProfileFailure: (state, action: PayloadAction<GetProfileFailurePayload>) => {
      state.isLoading = false
      state.isAuthenticated = false
      state.errors = action.payload.errors
    },
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload
    },
    logout: state => {
      window.location.href = '/'
      state.profile = null
      state.isAuthenticated = false
      localStorage.removeItem('authTokens')
    },
    getLessonsWatchedRequest: state => {
      state.isLoading = true
      state.errors = []
    },
    getLessonsWatchedSuccess: (state, action: PayloadAction<GetLessonWatchedSuccessPayload>) => {
      state.isLoading = false
      state.watchedLessons = action.payload.watchedLessons
      state.errors = []
    },
    getLessonsWatchedFailure: (state, action: PayloadAction<GetProfileFailurePayload>) => {
      state.isLoading = false
      state.errors = action.payload.errors
    },
    getFavoriteLessonsRequest: state => {
      state.isLoading = true
      state.errors = []
    },
    getFavoriteLessonsSuccess: (state, action: PayloadAction<GetFavoriteLessonSuccessPayload>) => {
      state.isLoading = false
      state.favoriteLessons = action.payload.favoriteLessons
      state.errors = []
    },
    getFavoriteLessonsFailure: (state, action: PayloadAction<GetProfileFailurePayload>) => {
      state.isLoading = false
      state.errors = action.payload.errors
    },
  },
})

export const {
  getProfileRequest,
  getProfileSuccess,
  getProfileFailure,
  logout,
  setIsAuthenticated,
  getLessonsWatchedRequest,
  getLessonsWatchedSuccess,
  getLessonsWatchedFailure,
  getFavoriteLessonsRequest,
  getFavoriteLessonsSuccess,
  getFavoriteLessonsFailure,
} = userSlice.actions

export const userReducer = userSlice.reducer

import { call, put, takeLatest } from 'redux-saga/effects'
import {
  getFavoriteLessonsFailure,
  getFavoriteLessonsRequest,
  getFavoriteLessonsSuccess,
  getLessonsWatchedFailure,
  getProfileRequest,
  setIsAuthenticated,
} from 'src/store/user'
import { FavoriteLessonResponse, LastLessonResponse, UserResponse } from 'src/service/api/api.types'
import { apiPrivate } from 'src/service/api/api.private'
import {
  getProfileSuccess,
  getProfileFailure,
  getLessonsWatchedSuccess,
  getLessonsWatchedRequest,
} from '.'

export function* updateLastLessons() {
  try {
    const res: LastLessonResponse = yield call(apiPrivate.getLastLessonsWatched.bind(apiPrivate))

    if (res.kind === 'ok') {
      yield put(getLessonsWatchedSuccess({ watchedLessons: res.data }))
    }
  } catch (errors) {
    // Sentry
    yield put(getLessonsWatchedFailure({ errors }))
  }
}

export function* updateFavoriteLessons() {
  try {
    const res: FavoriteLessonResponse = yield call(apiPrivate.getFavoriteLessons.bind(apiPrivate))

    if (res.kind === 'ok') {
      yield put(getFavoriteLessonsSuccess({ favoriteLessons: res.data }))
    }
  } catch (errors) {
    // Sentry
    yield put(getFavoriteLessonsFailure({ errors }))
  }
}

export function* updateProfileRequest() {
  try {
    const res: UserResponse = yield call(apiPrivate.getUserData.bind(apiPrivate))

    if (res.kind === 'ok') {
      res.data = {
        ...res.data,
        profilePhoto:
          res.data.profilePhoto ??
          'https://res.cloudinary.com/cordasemusica/image/upload/f_webp/v1678224793/avatar_zrtqux.png',
      }
      yield put(getProfileSuccess({ profile: res.data }))
      return
    }

    if (res.kind === 'unauthorized') {
      yield put(setIsAuthenticated(false))
    }
  } catch (errors) {
    // Sentry
    yield put(getProfileFailure({ errors }))
  }
}

export default function* userSaga() {
  yield takeLatest(getProfileRequest.type, updateProfileRequest)
  yield takeLatest(getLessonsWatchedRequest.type, updateLastLessons)
  yield takeLatest(getFavoriteLessonsRequest.type, updateFavoriteLessons)
}

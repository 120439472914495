import { all, fork } from 'redux-saga/effects'
import lessonSaga from 'src/store/lessons/saga'
import searchSaga from 'src/store/search/saga'
import teacherSaga from 'src/store/teachers/saga'
import newsSaga from 'src/store/news/saga'
import categoriesSaga from 'src/store/category/saga'
import testimonialSaga from "src/store/testimonials/saga";
import userSaga from './user/saga'

function* rootSaga() {
  yield all([
    fork(userSaga),
    fork(lessonSaga),
    fork(searchSaga),
    fork(teacherSaga),
    fork(newsSaga),
    fork(categoriesSaga),
    fork(testimonialSaga),
  ])
}

export default rootSaga
